import React from 'react';
import { translate } from 'react-i18next';

function CookieAlert(props) {
  const { t } = props;

  return (<div className={"cookie-alert" + (props.visible ? '' : ' isHidden')}>
    <div className="large-18 columns">
      <span>
        {t('cookieMsg')}
      </span>
      <a className="accept-button" onClick={props.handleAcceptCookie}>{t('common.accept')}</a>
    </div>
  </div>);
}

export default translate('translations')(CookieAlert);
