import React, { Component } from "react";
import { translate } from "react-i18next";
import FreamScroll from '../../shared/fream-scroll';
import styles from './index.scss';
import { actions as uiActions, ModalTypes } from 'shared/redux/ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class CtaBlock extends Component {
  constructor(props) {
    super(props);
    this.tryButtonClick = this.tryButtonClick.bind(this);
  }
  tryButtonClick() {
    this.props.showModal({
      modalType: ModalTypes.Registration,
    });
  }
  render() {
    const {t, title, children } = this.props;

    return (
      <FreamScroll component={'section'} id="get-started" animate="fadeInUp" duration={1} delay={0} offset={-400}>
        <div className="row typography">
          <FreamScroll className="small-12 medium-10 large-8 small-centered text-align-center" queueClass="anim" duration={1} queueDuration={.5} offset={-400}>
            <h2 className="anim" dangerouslySetInnerHTML={{ __html: t("ctablock.header") }} />
            <p className="anim" dangerouslySetInnerHTML={{ __html: t("ctablock.subheader") }} />
            <a className="btn" onClick={this.tryButtonClick}>{t("ctablock.trybtn")}</a>
          </FreamScroll>
        </div>
      </FreamScroll>
    );
  }
}

const {
  showModal,
} = uiActions;

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default translate('translations')(connect(
  null,
  mapDispatchToProps,
)(CtaBlock));
