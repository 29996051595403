import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import './footer.scss';
import Config from 'config';
import IconLogo from 'home/home/logo.svg';
import IconBackToTop from 'home/icons/back-to-top.svg';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import AssetHelper from 'AssetHelper';
import i18next from "i18next";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.scrollInterval = null;
    this.scrollTop = this.scrollTop.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  scrollTop() {
    clearInterval(this.scrollInterval);
    if (window.scrollY !== 0) {
      let startScroll = window.scrollY,
        stopScroll = 0,
        startTimestamp = Date.now(),
        animationLength = 500;

      function easeInOut(t) {
        return t > 0.5 ? 4 * Math.pow((t - 1), 3) + 1 : 4 * Math.pow(t, 3);
      }

      this.scrollInterval = window.setInterval(() => {
        let diffTime = Date.now() - startTimestamp,
          progress = diffTime / animationLength,
          y;

        progress = easeInOut(progress);

        progress = Math.max(Math.min(progress, 1), 0); // normalized value between 0-1
        y = startScroll + ((stopScroll - startScroll) * progress);

        window.scrollTo(0, y);
        if (progress >= 1) {
          clearInterval(this.scrollInterval);
        }
      }, 10);
    }
  }
  openModal() {
    this.props.openLoginModal();
  }
  render() {
    const { t, footerSectionOpen, setFooterSectionOpen } = this.props,
      FooterNavLink = (link, key) => {
        let checkExternalLink = new RegExp("^https://", "i"),
          isExternalLink = checkExternalLink.test(link.to);

        if (link.modal) {
          return <a onClick={this.openModal} key={key}>{t(link.translation)}</a>;
        } else if (isExternalLink) {
          return <a href={link.to} key={key} className={link.className} target="_blank" rel="noopener noreferrer">{t(link.translation)}</a>;
        } else if (link.to.indexOf('http')===0) {
          return <a href={link.to} key={key} target={link.target} className={link.className}>{t(link.translation)}</a>;
        } else {
          return <NavLink className={link.className} activeClassName="isSelected" to={link.to} target={link.target} key={key}>{t(link.translation)}</NavLink>;
        }
      },
      links1 = Config.home.ui.footerLinks[0].map((link, i) => {
        return FooterNavLink(link, i);
      }),
      // links2 = Config.home.ui.footerLinks[1].map((link, i) => {
      //   return FooterNavLink(link, i);
      // }),
      links3 = Config.home.ui.footerLinks[2].map((link, i) => {
        return FooterNavLink(link, i);
      });

    let logo;

    if (Config.home.ui.footerLogo) {
      logo = <NavLink exact={true} to="/" className="footer__logo"><img src={Config.home.ui.footerLogo} /></NavLink>;
    } else {
      logo = <NavLink exact={true} to="/" className="footer__logo"><IconLogo /></NavLink>;
    }

    return (
      <footer className="footer wrapper">
        <div className="footer__how-it-works">
          <div className={footerSectionOpen ? 'open' : 'close'}>
            <h3 className="footer__how-it-works--heading">{t('footer.title1')}</h3>
            <p className="footer__how-it-works--description">{t('footer.description1')}</p>
            <p className="footer__how-it-works--description">{t('footer.description2')}</p>
            <p className="footer__how-it-works--description">{t('footer.description3')}</p>

            <h3 className="footer__how-it-works--heading">{t('footer.title2')}</h3>
            <p className="footer__how-it-works--description">{t('footer.description4')}</p>
            <p className="footer__how-it-works--description">{t('footer.description5')}</p>

            <h3 className="footer__how-it-works--heading">{t('footer.title3')}</h3>
            <p className="footer__how-it-works--description">{t('footer.description6')}</p>
            <p className="footer__how-it-works--description">{t('footer.description7')}</p>

            <h3 className="footer__how-it-works--heading">{t('footer.title4')}</h3>
            <p className="footer__how-it-works--description">{t('footer.description8')}</p>
          </div>

          {footerSectionOpen ? (
            <IconExpandLess onClick={() => setFooterSectionOpen(false)} className="footer__how-it-works--toggle-icon" />
          ) : (
            <IconExpandMore onClick={() => setFooterSectionOpen(true)} className="footer__how-it-works--toggle-icon" />
          )}
        </div>

        <div className="footer__columns-top">

          <div className="footer__column footer__column--evryplace">
            <p className="footer__column-heading">{t('footer.evryplace')}</p>
            <div className="footer__column-links">
              {links1}
            </div>
          </div>

          <div className="footer__column footer__column--help">
            <p className="footer__column-heading">{t('footer.needHelp')}</p>
            <div className="footer__column-links">
              <a href="/faq" target="_blank">{t('footer.faq')}</a>
              <a href={i18next.language.includes('pl') ? '/pl/knowledge-base' : '/knowledge-base'} target="_blank">{t('footer.knowledgeBase')}</a>
            </div>
          </div>

          <div className="footer__column footer__column--more">
            <p className="footer__column-heading">{t('footer.more')}</p>
            <div className="footer__column-links">
              {links3}
            </div>
          </div>

        </div>

        <div className="footer__columns-bottom">

          <div className="footer__column footer__column--logos">
            <a href="https://play.google.com/store/apps/details?id=pl.fream.evryplace.flat.evr" target="_blank">
              <img className="footer__img" src={AssetHelper.staticPath('/static/home/media/google.svg')} alt="google play logo" />
            </a>
            <a href="https://itunes.apple.com/pl/app/evryplace/id1276176541?l=pl&mt=8" target="_blank">
              <img className="footer__img" src={AssetHelper.staticPath('/static/home/media/appstore.svg')} alt="app store logo" />
            </a>
            <a href="https://www.oculus.com/experiences/gear-vr/1739742929422865/" target="_blank">
              <img className="footer__img" src={AssetHelper.staticPath('/static/home/media/oculus.svg')} alt="" />
            </a>
          </div>

          <div className="footer__column footer__column--terms">
            {logo}
            <div className="footer__copyright">
              <p>{t('footer.copy')}</p>
              <p>
                <a className="footer__link" href="/terms" target="_blank">{t('footer.terms')}</a>&nbsp;
                <a className="footer__link" href="/privacy" target="_blank">{t('footer.privacy')}</a>
              </p>
            </div>
          </div>
        </div>
        <a className="footer__gottotop" onClick={this.scrollTop}>
          <IconBackToTop />
          <div>{t('footer.gototop')}</div>
        </a>

      </footer>
    );
  }
}
export default translate('translations')(Footer);
