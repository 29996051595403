import React, {Component, Fragment} from "react";
import {translate} from "react-i18next";
import "./index.scss";
import Modal from 'components/modal/index.jsx';
import Typography from '@material-ui/core/Typography';
import AssetHelper from 'AssetHelper';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

class ContactModal extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleCloseModal() {
    const { onClose } = this.props;
    if(onClose) {
      onClose();
    }
  }
  render() {
    const {t, open} = this.props;
    const blogLink = i18next.language.includes('pl')
      ? 'https://evryplace.com/pl/blog-pl/?p=632'
      : 'https://evryplace.com/blog/?p=636';

    return (
      <Fragment>
        <Modal open={open} className="coronavirus-modal" onClose={this.handleCloseModal}>
          <div className="coronavirus-modal__image-wrapper">
            <img src={AssetHelper.staticPath('/static/home/home/real-estate-industry-at-home.jpg')} alt='coronavirusInfo img' />
          </div>
          <div className="coronavirus-modal-wrapper">
            <h3>{t('coronaVirusInfoModal.title')}</h3>
            <Grid container direction="column" spacing={8}>
              <Grid item>
                <Typography>{t('coronaVirusInfoModal.body1')}</Typography>
              </Grid>
              <Grid item>
                <Typography>{t('coronaVirusInfoModal.body2')}</Typography>
              </Grid>
              <Grid item>
                <Typography className="bold">{t('coronaVirusInfoModal.body3')}</Typography>
              </Grid>
              <Grid item>
                <a href={blogLink}className="button">{t('coronaVirusInfoModal.buttonText')}</a>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default translate("translations")(ContactModal);
