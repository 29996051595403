import React, {Component} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {translate} from "react-i18next";
import PropTypes, { shape } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Grid from '@material-ui/core/Grid';

const styles = () => ({
  wrapper: {
    margin: '0 auto 24px auto'
  },
  doneIcon: {
    marginTop: '10px',
    color: '#4bcc21',
    width: '60px',
    height: '60px',
  },
})

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { onClose } = this.props;

    if(onClose) {
      onClose();
    }
  }

  render() {
    const { t, classes, modalTitle, modalMessage, ...props } = this.props || {};
    return (
      <Dialog {...props}>
        {modalTitle && <DialogTitle>{modalTitle}</DialogTitle>}
        <DialogContent >
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item>{modalMessage}</Grid>
            <Grid item><CheckCircleOutlineIcon className={classes.doneIcon} /></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.wrapper}>
          <button className="button" onClick={this.handleClose}>{t('common.close')}</button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  t: PropTypes.shape({}).isRequired,
}

ConfirmationDialog.defaultProps = {
  onClose: null,
  open: false,
  modalTitle: '',
  modalMessage: '',
}

export default compose(
  translate("translations"),
  withStyles(styles),
)(ConfirmationDialog);
